import {
	createItem,
	readItem,
	readItems,
	updateItem,
	type Query,
} from '@directus/sdk';
import type {
	Schema,
	SiteIntervalSummaries,
} from '@thunder/core/src/services/baas/directus/schema';
import { SiteIntervalSummary } from '../site-interval-summary.model';

interface State {
	_list: SiteIntervalSummary[];
	_current?: SiteIntervalSummary;
}

export const useSiteIntervalSummaryStore = () => {
	const { $baas } = useNuxtApp();

	return defineStore('site-interval-summaries', {
		state: (): State => ({
			_list: [],
			_current: undefined,
		}),
		getters: {
			current: state => state._current,
			list: state => state._list,

			get: state => {
				return (id: number): SiteIntervalSummary | null => {
					return (
						state._list?.find((item: SiteIntervalSummary) => item.id === id) ??
						null
					);
				};
			},
		},
		actions: {
			async search(query?: Query<Schema, SiteIntervalSummaries>) {
				const data = await $baas.request(
					readItems('site_interval_summaries', query),
				);

				for (const item of data) {
					this.listUpsert(item.id, item);
				}

				return data.map(
					(_siteIntervalSummary: any) =>
						new SiteIntervalSummary(_siteIntervalSummary),
				);
			},

			async fetchMany(query?: Query<Schema, SiteIntervalSummaries>) {
				const data = await $baas.request(
					readItems('site_interval_summaries', query),
				);

				for (const item of data) {
					this.listUpsert(item.id, item);
				}

				return this._list;
			},

			async fetch(id: number, query?: Query<Schema, SiteIntervalSummaries>) {
				const data = await $baas.request(
					readItem('site_interval_summaries', id, query),
				);

				this.listUpsert(data.id, data);
			},

			async update(id: number, data: Partial<SiteIntervalSummary>) {
				const updated = await $baas.request(
					updateItem('site_interval_summaries', id, data as any),
				);

				this.listUpsert(updated.id, updated);
			},

			listUpsert(id: string | number, data: object) {
				const index = this._list.findIndex(_item => _item.id === id);

				if (index >= 0) {
					this._list[index] = new SiteIntervalSummary(data);
				} else {
					this._list.push(new SiteIntervalSummary(data));
				}
			},

			setCurrent(data: Partial<SiteIntervalSummaries>) {
				this._current = new SiteIntervalSummary(data);
				this.listUpsert(this._current.id, data);
			},

			async store(data: Partial<SiteIntervalSummaries>) {
				const created = await $baas.request(
					createItem('site_interval_summaries', data),
				);

				this.listUpsert(created.id, created);

				return new SiteIntervalSummary(created);
			},
		},
	});
};
