import { default as cashupdKROmogaj8Meta } from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/pages/cashup.vue?macro=true";
import { default as forgot_45password5E7ArFEqhMMeta } from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/pages/forgot-password.vue?macro=true";
import { default as index4fhfRRtS37Meta } from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/pages/index.vue?macro=true";
import { default as login7AO5fdRVIlMeta } from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/pages/login.vue?macro=true";
import { default as not_45authorizedoZ4h6EiDzyMeta } from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/pages/not-authorized.vue?macro=true";
import { default as reset_45passwordrOAzFh2zp6Meta } from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/pages/reset-password.vue?macro=true";
import { default as indexaIFFlYFMzsMeta } from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/pages/work-order/[id]/index.vue?macro=true";
import { default as indexK4O6BYhoarMeta } from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/pages/work-order/create/index.vue?macro=true";
import { default as collectedlguXqoqrzSMeta } from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/pages/work-order/status/collected.vue?macro=true";
import { default as completedaPxEkZEx2sMeta } from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/pages/work-order/status/completed.vue?macro=true";
import { default as processinghgQMkrNwdYMeta } from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/pages/work-order/status/processing.vue?macro=true";
export default [
  {
    name: cashupdKROmogaj8Meta?.name ?? "cashup",
    path: cashupdKROmogaj8Meta?.path ?? "/cashup",
    meta: cashupdKROmogaj8Meta || {},
    alias: cashupdKROmogaj8Meta?.alias || [],
    redirect: cashupdKROmogaj8Meta?.redirect,
    component: () => import("/home/alexander/symbyte/src/thunderbrothers/fullstack-app/pages/cashup.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password5E7ArFEqhMMeta?.name ?? "forgot-password",
    path: forgot_45password5E7ArFEqhMMeta?.path ?? "/forgot-password",
    meta: forgot_45password5E7ArFEqhMMeta || {},
    alias: forgot_45password5E7ArFEqhMMeta?.alias || [],
    redirect: forgot_45password5E7ArFEqhMMeta?.redirect,
    component: () => import("/home/alexander/symbyte/src/thunderbrothers/fullstack-app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: index4fhfRRtS37Meta?.name ?? "index",
    path: index4fhfRRtS37Meta?.path ?? "/",
    meta: index4fhfRRtS37Meta || {},
    alias: index4fhfRRtS37Meta?.alias || [],
    redirect: index4fhfRRtS37Meta?.redirect,
    component: () => import("/home/alexander/symbyte/src/thunderbrothers/fullstack-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login7AO5fdRVIlMeta?.name ?? "login",
    path: login7AO5fdRVIlMeta?.path ?? "/login",
    meta: login7AO5fdRVIlMeta || {},
    alias: login7AO5fdRVIlMeta?.alias || [],
    redirect: login7AO5fdRVIlMeta?.redirect,
    component: () => import("/home/alexander/symbyte/src/thunderbrothers/fullstack-app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: not_45authorizedoZ4h6EiDzyMeta?.name ?? "not-authorized",
    path: not_45authorizedoZ4h6EiDzyMeta?.path ?? "/not-authorized",
    meta: not_45authorizedoZ4h6EiDzyMeta || {},
    alias: ["/pages/misc/not-authorized"].concat(not_45authorizedoZ4h6EiDzyMeta?.alias || []),
    redirect: not_45authorizedoZ4h6EiDzyMeta?.redirect,
    component: () => import("/home/alexander/symbyte/src/thunderbrothers/fullstack-app/pages/not-authorized.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordrOAzFh2zp6Meta?.name ?? "reset-password",
    path: reset_45passwordrOAzFh2zp6Meta?.path ?? "/reset-password",
    meta: reset_45passwordrOAzFh2zp6Meta || {},
    alias: reset_45passwordrOAzFh2zp6Meta?.alias || [],
    redirect: reset_45passwordrOAzFh2zp6Meta?.redirect,
    component: () => import("/home/alexander/symbyte/src/thunderbrothers/fullstack-app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: indexaIFFlYFMzsMeta?.name ?? "work-order-id",
    path: indexaIFFlYFMzsMeta?.path ?? "/work-order/:id()",
    meta: indexaIFFlYFMzsMeta || {},
    alias: indexaIFFlYFMzsMeta?.alias || [],
    redirect: indexaIFFlYFMzsMeta?.redirect,
    component: () => import("/home/alexander/symbyte/src/thunderbrothers/fullstack-app/pages/work-order/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexK4O6BYhoarMeta?.name ?? "work-order-create",
    path: indexK4O6BYhoarMeta?.path ?? "/work-order/create",
    meta: indexK4O6BYhoarMeta || {},
    alias: indexK4O6BYhoarMeta?.alias || [],
    redirect: indexK4O6BYhoarMeta?.redirect,
    component: () => import("/home/alexander/symbyte/src/thunderbrothers/fullstack-app/pages/work-order/create/index.vue").then(m => m.default || m)
  },
  {
    name: collectedlguXqoqrzSMeta?.name ?? "work-order-status-collected",
    path: collectedlguXqoqrzSMeta?.path ?? "/work-order/status/collected",
    meta: collectedlguXqoqrzSMeta || {},
    alias: collectedlguXqoqrzSMeta?.alias || [],
    redirect: collectedlguXqoqrzSMeta?.redirect,
    component: () => import("/home/alexander/symbyte/src/thunderbrothers/fullstack-app/pages/work-order/status/collected.vue").then(m => m.default || m)
  },
  {
    name: completedaPxEkZEx2sMeta?.name ?? "work-order-status-completed",
    path: completedaPxEkZEx2sMeta?.path ?? "/work-order/status/completed",
    meta: completedaPxEkZEx2sMeta || {},
    alias: completedaPxEkZEx2sMeta?.alias || [],
    redirect: completedaPxEkZEx2sMeta?.redirect,
    component: () => import("/home/alexander/symbyte/src/thunderbrothers/fullstack-app/pages/work-order/status/completed.vue").then(m => m.default || m)
  },
  {
    name: processinghgQMkrNwdYMeta?.name ?? "work-order-status-processing",
    path: processinghgQMkrNwdYMeta?.path ?? "/work-order/status/processing",
    meta: processinghgQMkrNwdYMeta || {},
    alias: processinghgQMkrNwdYMeta?.alias || [],
    redirect: processinghgQMkrNwdYMeta?.redirect,
    component: () => import("/home/alexander/symbyte/src/thunderbrothers/fullstack-app/pages/work-order/status/processing.vue").then(m => m.default || m)
  }
]