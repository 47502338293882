import {
	SiteIntervalSummaries,
	SiteSummaryInterval,
} from '@thunder/core/src/services/baas/directus/schema';
import dayjs from 'dayjs';

export class SiteIntervalSummary extends SiteIntervalSummaries {
	constructor(data: unknown) {
		super();

		Object.assign(this, data);
	}

	public get end(): Date | string {
		// eslint-disable-next-line sonarjs/no-small-switch
		switch (this.interval) {
			case SiteSummaryInterval.daily:
				return dayjs(this.start).add(1, 'day').endOf('day').toISOString();
		}
	}

	toJSON() {
		return {
			...this,
		};
	}
}
