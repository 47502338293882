import payloads_kLsxztYc8f from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/plugins/payloads.ts";
import revive_payload_client_4sVQNw7RlN from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/.nuxt/sentry-client-config.mjs";
import plugin_1doCGnr5OF from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/node_modules/nuxt-directus/dist/runtime/plugin.mjs";
import plugin_AOuQ1DYzjk from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_t2GMTTFnMT from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import index_kyvreQaQna from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/plugins/vuetify/index.ts";
import index_pJsEXjjc09 from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/plugins/iconify/index.ts";
import index_D6fDksuOQB from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/plugins/casl/index.ts";
import index_cRE09ZHMc3 from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/plugins/baas/index.ts";
import index_Szpq2WCYUO from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/plugins/country-flag/index.ts";
import layouts_mPotmY5lsV from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/plugins/layouts.ts";
import webfontloader_client_iX85i8j6U3 from "/home/alexander/symbyte/src/thunderbrothers/fullstack-app/plugins/webfontloader.client.ts";
export default [
  payloads_kLsxztYc8f,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_1doCGnr5OF,
  plugin_AOuQ1DYzjk,
  plugin_t2GMTTFnMT,
  chunk_reload_client_UciE0i6zes,
  index_kyvreQaQna,
  index_pJsEXjjc09,
  index_D6fDksuOQB,
  index_cRE09ZHMc3,
  index_Szpq2WCYUO,
  layouts_mPotmY5lsV,
  webfontloader_client_iX85i8j6U3
]