import { parseErrors } from '@/thunder/common/utils/validation';
import type { SiteIntervalSummary } from '@/thunder/modules/site-interval-summaries/site-interval-summary.model';
import { type Query } from '@directus/sdk';
import type {
	Schema,
	SiteIntervalSummaries,
} from '@thunder/core/src/services/baas/directus/schema';

import { SiteSummaryInterval } from '@thunder/core/src/services/baas/directus/schema';

export const useDailyFilteredSiteIntervalSummaries = (
	query: Ref<Query<Schema, SiteIntervalSummaries>>,
) => {
	// 👉 composables
	const siteIntervalSummaryStore = useSiteIntervalSummaryStore()();
	const siteStore = useSiteStore()();

	// 👉 refs
	const loadingData = ref(false);
	const error = ref<string | undefined>('');
	const dailySummaries = ref<SiteIntervalSummary[]>([]);

	// 👉 setups
	/**
	 * try and hardcode the current site id and 'daily'
	 * interval to the filter.
	 */
	if (query.value.filter) {
		query.value.filter = {
			...query.value.filter,
			site: {
				_eq: siteStore.current?.id,
			},
			interval: {
				_eq: SiteSummaryInterval.daily,
			},
		};
	} else {
		query.value.filter = {
			site: {
				_eq: siteStore.current?.id,
			},
			interval: {
				_eq: SiteSummaryInterval.daily,
			},
		};
	}

	const loadData = async () => {
		loadingData.value = true;

		try {
			dailySummaries.value = await siteIntervalSummaryStore.search(query.value);
		} catch (_error: any) {
			const errors = parseErrors(_error);

			error.value = errors.error;
		} finally {
			loadingData.value = false;
		}
	};

	return {
		loadData,
		loadingData,
		dailySummaries,
		error,
	};
};
